import { rootStore } from '../stores/rootStore';
import { CookieUtils } from './cookies';

export class AuthError extends Error {
  constructor(readonly message: string) {
    super(message);
  }
  readonly code = 'AUTH_ERROR';
  status: null | number = null;
}

const withErrorHandler = async <T>(cb: () => Promise<T | undefined>) => {
  try {
    const res = await cb();
    return res;
  } catch (error) {
    if (error instanceof AuthError) {
      rootStore.errorStore.setError(error);
      return;
    }
    if (error instanceof Error) {
      rootStore.errorStore.setError(error);
      return;
    }

    const err = new Error('Неизвестная ошибка');
    err.name = 'Error';
    rootStore.errorStore.setError(err);
  }
};

const handleErrorResponse = async (response: Response) => {
  // handle 500
  if (response.status === 500) {
    const err = new Error();
    err.message = (await response.text()) || 'Что-то пошло не так :(';
    err.name = response.statusText || 'Ошибка сервера';
    throw err;
  }

  if (response.status === 401) {
    // clear cookies
    CookieUtils.clearToken();
    rootStore.authStore.setUser(undefined);

    const error = new AuthError('Вероятно, вас давно не было. Пожалуйста, авторизуйтесь заново');
    error.name = 'Сессия устарела';
    error.status = 401;
    throw error;
  }

  const err = new Error();
  err.message = (await response.text()) || 'Что-то пошло не так :(';
  err.name = response.statusText || 'Неизвестная ошибка';

  throw err;
};

export const api = {
  hostUrl: import.meta.env.VITE_API_URL,
  dev: import.meta.env.DEV,

  post: async function <T>(url: string, bodyRequest: unknown) {
    return withErrorHandler<T>(async () => {
      const token = CookieUtils.getToken();
      const response = await fetch(`${api.hostUrl}${url}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(token && {
            Authorization: `NLB ${token}`,
          }),
        },
        body: JSON.stringify(bodyRequest),
      });

      if (response.ok) {
        return response.json() as T;
      } else {
        await handleErrorResponse(response);
      }
    });
  },
  get: async function <T>(path: string) {
    return withErrorHandler<T>(async () => {

      const token = CookieUtils.getToken();

      const url = new URL(`${api.hostUrl}${path}`)
      this.dev && url.searchParams.set("format", 'json')
      
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(token && {
            Authorization: `NLB ${token}`,
          
          }),
        },
      });

      if (response.ok) {
        return response.json() as T;
      } else {
        await handleErrorResponse(response);
      }
    });
  },
};
