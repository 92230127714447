import { useNavigate } from 'react-router-dom';
// import RoomImg from '../../assets/img-2500.jpeg';
import { Button } from '../../components/shared/Button/Button';
import { Chip } from './components/Chip';
import { Price } from './components/Price';
import { Nights } from './components/Nights';
import { useEffect } from 'react';
import { useRootStore } from '../../stores/useRootStore';
import { observer } from 'mobx-react-lite';
import { useNightsCounter } from '../../utils/useNightsCounter';
import { formatDate, parseDate } from '../../utils/dates';
import { Skeleton } from '../../components/Skeleton';
import noun from 'plural-ru';
import { NotFoundPlug } from '../../components/Plug/NotFoundPlug';
import { TableRow } from './components/TableRow';

const skeletonItem = {
  id: 0,
  crs_number: '',
  room_type: '',
  rate: {
    id: 0,
    code: '',
    name: '',
    guest_non_refundable: false,
    short_description: '',
    long_description: '',
  },
  price: '',
  departure: '2024-08-11',
  arrival: '2024-08-12',
  status: '',
  adults: 0,
  get_guest_childs: 0,
  room_type_props: {
    img: '',
    square: 0,
  },
};

const Skeletons = () => {
  return Array.from(new Array(5), (_, i) => i).map(() => (
    <BookingItem key={1} data={skeletonItem} loading />
  ));
};

const NotFound = () => {
  return (
    <NotFoundPlug
      title={'Список бронирований пуст'}
      text="Оформленные Вами бронирования появятся здесь"
    ></NotFoundPlug>
  );
};

export const BookingList = observer(() => {
  const { bookingsStore, configStore } = useRootStore();

  useEffect(() => {
    // TODO hotelId guard
    if (!configStore.hotelId) {
      return;
    }
    bookingsStore.loadBookinglist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configStore.hotelId]);

  return (
    <main className="mt-6 px-5 xl:mt-10">
      {!bookingsStore.loadingList && !bookingsStore.bookingList.length && <NotFound />}
      {bookingsStore.loadingList && <Skeletons />}
      {!bookingsStore.loadingList && bookingsStore.bookingList.map((booking) => (
        <BookingItem key={booking.id} data={booking} />
      ))}
    </main>
  );
});


export type RateSummary = {
  id: number;
  code: string;
  name: string;
  guest_non_refundable: boolean;
  short_description: string;
  long_description: string;
};

const BookingItem = ({
  data,
  loading,
}: {
  data: {
    id: number;
    crs_number: string;
    room_type: string;
    rate: RateSummary;
    price: string;
    departure: string;
    arrival: string;
    status: string;
    adults: number;
    get_guest_childs: number;
    room_type_props: {
      img: string;
      square: number;
    };
  };
  loading?: boolean;
}) => {
  const {
    id,
    crs_number,
    room_type,
    rate,
    price,
    departure,
    arrival,
    status,
    adults,
    get_guest_childs: childs,
    room_type_props,
  } = data;
  const navigate = useNavigate();
  const showBookingDetails = () => {
    navigate(`/booking-list/${id}`);
  };

  const nightsStat = useNightsCounter({ from: parseDate(arrival), to: parseDate(departure) });
  const adultsStat = `${adults} ${noun(adults, 'взрослый', 'взрослых')}`;
  const childsStat = childs ? `, ${childs} ${noun(childs, 'ребёнок', 'ребёнка', 'детей')}` : '';
  const guestStat = adultsStat + childsStat;

  return (
    <article className="mb-10 flex flex-col justify-between xl:flex-row ">
      <Chip className="mb-3 self-start xl:hidden">{status}</Chip>
      <div className="hidden h-[200px] w-[280px] xl:block">
        <Skeleton loading={loading} className="h-full w-full">
          <img src={room_type_props.img} alt="room image" className="h-full w-full object-cover" />
        </Skeleton>
      </div>
      <div className="flex-1 xl:pl-4">
        <span className="mb-1 font-light">
          <Skeleton loading={loading} className="mb-1 h-[20px] w-[150px]">
            Заказ № {crs_number}
          </Skeleton>
        </span>
        <p className="mb-1 text-lg font-medium text-neutral-900">
          <Skeleton loading={loading} className="h-[28px] w-3/5">
            {room_type}
          </Skeleton>
        </p>
        <p className="text-lg font-medium text-neutral-900">
          <Skeleton loading={loading} className="h-[28px] w-[220px]">
            {formatDate(arrival)} - {formatDate(departure)}
          </Skeleton>
        </p>
        <table className="mt-4 font-light text-neutral-700">
          <TableRow label="Проживание" loading={loading} skeletonClass={['w-[110px]', 'w-[50px]']}>
            {nightsStat}
          </TableRow>
          <TableRow label="Гости" loading={loading} skeletonClass={['w-[50px]', 'w-[100px]']}>
            {guestStat}
          </TableRow>
          <TableRow label="Тариф" loading={loading} skeletonClass={['w-[60px]', 'w-[50px]']}>
            {rate.code}
          </TableRow>
          <TableRow label="Площадь" loading={loading} skeletonClass={['w-[60px]', 'w-[200px]']}>
            {room_type_props.square}{' '}
            <span>
              м<sup>2</sup>
            </span>
          </TableRow>
        </table>
      </div>

      <div className="mt-6 flex flex-col items-end justify-between">
        <Skeleton loading={loading} className="h-[28px] w-[120px]">
          <Chip className="hidden xl:block">{status}</Chip>
        </Skeleton>
        <div className="w-full ">
          <div className="mb-2 flex items-baseline xl:flex-col xl:items-end">
            <Skeleton loading={loading} className="mb-1.5 h-6 w-[80px]">
              <Price>{Number(price).toLocaleString('ru')}</Price>
            </Skeleton>
            <Skeleton loading={loading} className="h-5 w-[100px]">
              <Nights>за {nightsStat}</Nights>
            </Skeleton>
          </div>
          <div className="xl:text-right">
            <Skeleton loading={loading} className="h-[48px] w-[115px]">
              <Button style="secondary" className="w-full xl:w-auto" onClick={showBookingDetails}>
                Подробнее
              </Button>
            </Skeleton>
            {/* <Button className="hidden xl:inline-block w-full xl:w-auto xl:ml-2 mt-2 xl:mt-0">Оплатить всё</Button> */}
          </div>
        </div>
      </div>
    </article>
  );
};
