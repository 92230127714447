// import './select.css';

import {
  autoUpdate,
  flip,
  useFloating,
  useInteractions,
  useListNavigation,
  useTypeahead,
  useClick,
  useListItem,
  useDismiss,
  useRole,
  FloatingFocusManager,
  FloatingList,
  size,
} from '@floating-ui/react';
import * as React from 'react';

import DropdownIcon from '../../../assets/chevron-up-icon.svg?react';

interface SelectContextValue {
  activeIndex: number | null;
  selectedIndex: number | null;
  getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
  handleSelect: (index: number | null) => void;
}

const SelectContext = React.createContext<SelectContextValue>({} as SelectContextValue);

export function Select({
  children,
  placeholder = 'Выбрать',
  options,
  value,
  onChange,
}: {
  value: { id: number; label: string } | null;
  options: { id: number; label: string; disabled?: boolean }[];
  onChange: (id: number | null) => void;
  children: React.ReactNode;
  placeholder: string;
}) {
  const elementsRef = React.useRef<Array<HTMLElement | null>>([]);
  const labelsRef = React.useRef<Array<string | null>>([]);

  const [isOpen, setIsOpen] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(
    value ? options.findIndex((o) => o.id == value.id) : null,
  );
  const [selectedLabel, setSelectedLabel] = React.useState<string | null>(
    value ? value.label : null,
  );

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      flip(),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          });
        },
      }),
    ],
  });

  const handleSelect = React.useCallback(
    (index: number | null) => {
      setSelectedIndex(index);
      setIsOpen(false);
      if (index !== null) {
        setSelectedLabel(labelsRef.current[index]);
      }
      onChange(index);
    },
    [onChange],
  );

  function handleTypeaheadMatch(index: number | null) {
    if (isOpen) {
      setActiveIndex(index);
    } else {
      handleSelect(index);
    }
  }

  const listNav = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
  });
  const typeahead = useTypeahead(context, {
    listRef: labelsRef,
    activeIndex,
    selectedIndex,
    onMatch: handleTypeaheadMatch,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    listNav,
    typeahead,
    click,
    dismiss,
    role,
  ]);

  const selectContext = React.useMemo(
    () => ({
      activeIndex,
      selectedIndex,
      getItemProps,
      handleSelect,
    }),
    [activeIndex, selectedIndex, getItemProps, handleSelect],
  );

  React.useEffect(() => {
    // if (value === null) {
    //   return
    // }
    const idx = value ? options.findIndex((o) => o.id == value.id) : null;
    setSelectedIndex(idx);

    setSelectedLabel(value?.label || null);
  }, [value, options]);

  return (
    <>
      <div
        ref={refs.setReference}
        tabIndex={0}
        {...getReferenceProps()}
        className="flex w-full cursor-pointer select-none border border-neutral-50 bg-neutral-0 py-3 pl-5 pr-2 text-lg"
      >
        <span className="flex-1 font-light">
          {selectedLabel ?? <span className="text-neutral-70">{placeholder}</span>}
        </span>
        <span className="inline-block">
          <DropdownIcon className={`h-6 w-6 ${isOpen ? '' : 'rotate-180'}`} />
        </span>
      </div>
      <SelectContext.Provider value={selectContext}>
        {isOpen && (
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
              className="flex flex-col bg-neutral-0 py-3 text-lg shadow-xl outline-0 z-10"
            >
              <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
                {children}
              </FloatingList>
            </div>
          </FloatingFocusManager>
        )}
      </SelectContext.Provider>
    </>
  );
}

function Option({ label, disabled }: { label: string; disabled?: boolean }) {
  const { activeIndex, selectedIndex, getItemProps, handleSelect } =
    React.useContext(SelectContext);

  const { ref, index } = useListItem({ label });

  const isActive = activeIndex === index;
  const isSelected = selectedIndex === index;

  return (
    <button
      ref={ref}
      role="option"
      aria-selected={isActive && isSelected}
      tabIndex={isActive ? 0 : -1}
      style={{
        fontWeight: isSelected ? 'bold' : '',
      }}
      className={`px-5 py-3 text-left ${isActive ? 'bg-neutral-30' : ''}`}
      {...getItemProps({
        onClick: () => handleSelect(index),
      })}
      disabled={disabled}
    >
      {label}
    </button>
  );
}

function CustomOption({
  children,
  label,
  onClick,
}: {
  children: React.ReactNode;
  label: string;
  onClick: () => void;
}) {
  const { activeIndex, selectedIndex, getItemProps } = React.useContext(SelectContext);

  const { ref, index } = useListItem({ label });

  const isActive = activeIndex === index;
  const isSelected = selectedIndex === index;

  return (
    <button
      ref={ref}
      role="option"
      aria-selected={isActive && isSelected}
      tabIndex={-1}
      className={` text-left`}
      {...getItemProps({
        onClick: () => onClick?.(),
      })}
    >
      {children}
    </button>
  );
}

Select.Option = Option;
Select.CustomOption = CustomOption;
